import React from 'react'
import './Navbar.css'

const Navbar = () => {
    return (
        <>
            <input type="checkbox" id="menu-toggle" />
            <label for="menu-toggle" class="menu-icon">&#9776;</label>
            <nav class="navbar" style={ { position: 'fixed' } } >
                <div class="container">
                    <a href="#home" className='logo'>Real Software</a>
                    <ul class="nav-links">
                        <li><a href="#">Home</a></li>
                        <li><a href="#about">About</a></li>
                        <li><a href="#serve">Serve</a></li>
                        <li><a href="#blog">Blog</a></li>
                        <li><a href="#joiUs">Join Us</a></li>
                        <li><a href="#">Contact</a></li>
                    </ul>
                </div>
            </nav>


        </>
    )
}

export default Navbar