import React from 'react'
import './team.css'
import solu from './image/solut.jpg';
const Team = () => {
    return (
        <>
            <div className='team'>
                <div className='detail'>
                    <h3>Application Engineering</h3>
                    <p className='til'>
                        Application development, Product Engineering and Digital Assurance services form part of our core service offerings delivering innovation…
                    </p>
                    <div className='overview'>

                        <div className='topic'>
                            <div className='lin'></div>
                            <h4 className='hed'>Data & Analytics</h4>
                        </div>

                        <p className='dit'>
                            Our services help organizations use data &
                            analytics, ML, GenAI & LLM’s to create new business
                            models and revenue streams.</p>

                    </div>
                    <div className='deta'>
                        <div className='cexperi'>
                            <h5>22 Years Experience</h5>
                            <p>Lorem ipsum dolor sit amet consectetur</p>
                        </div>
                        <div className='cteam'>
                            <h5>Best Certification Team</h5>
                            <p>Lorem ipsum dolor sit amet consectetur</p>
                        </div>
                        <div className='crevi'>
                            <h5>Unlimited Revision</h5>
                            <p>Lorem ipsum dolor sit amet consectetur</p>
                        </div>
                    </div>
                </div>


                <div className='slImg'>
                    <img src={ solu } alt="" />
                </div>
            </div> <br />
        </>
    )
}

export default Team