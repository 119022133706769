import React from 'react'
import './Home.css'
import img from './image/img.png';
import logo1 from './image/logo-1.png';
import logo2 from './image/logo-cetech.png';
import logo3 from './image/logoipsum-2.png';
import logo4 from './image/logoipsum-3.png';
import serv from './image/serv.jpg';
import serv2 from './image/serv2.jpg';

const Home = () => {
    return (
        <>
            <div>
                <div className='pag' id='home'>

                    <div className='wl'>
                        <div className='hd'>
                            <h1>Digital Engineering Service</h1>
                            <h2>
                                A Digital Engineering Company
                                Building Morden Solutions Across Data and Application
                            </h2>
                            <button>GET STARTED</button>
                        </div>
                        <div className='img'>
                            <img src={ img } alt="" />
                        </div>
                    </div>
                </div>


                {/* Our Clients */ }

                {/* <div className='cli'>
                    <div className='logos'>
                        <div className='logos-slide'>
                            <img src={ logo1 } alt="" />
                            <img src={ logo2 } alt="" />
                            <img src={ logo3 } alt="" />
                            <img src={ logo4 } alt="" />
                        </div>
                        <div className='logos-slide'>
                            <img src={ logo1 } alt="" />
                            <img src={ logo2 } alt="" />
                            <img src={ logo3 } alt="" />
                            <img src={ logo4 } alt="" />
                        </div>
                        <div className='logos-slide'>
                            <img src={ logo1 } alt="" />
                            <img src={ logo2 } alt="" />
                            <img src={ logo3 } alt="" />
                            <img src={ logo4 } alt="" />
                        </div>
                    </div>
                </div> */}

                {/* Service */ }

                <div className='Service'>

                    <div className='pic'>
                        <div className='imgGrid'>
                            <img className='ser' src={ serv } alt="" />
                            <img className='ser2' src={ serv2 } alt="" />
                            <div className='set'>
                                <i class='bx bx-cog'></i>
                                <h4>
                                    1,2K+ Project Done
                                </h4>
                                <p>Small Until High Projects</p>
                            </div>
                        </div>

                    </div>
                    <div className='inf' id='about'>
                        <p className='about'>ABOUT US</p>
                        <h3>
                            Digital Engineering Success Stories
                        </h3>
                        <p className='text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec <br />
                            ullamcorper mattis, pulvinar dapibus leo.
                        </p>
                        <p className='wri'>
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus,<br /> luctus nec
                            ullamcorper mattis, pulvinar dapibus leo.'
                        </p>
                        <div className='time'>
                            <i class="fa-solid fa-clock-rotate-left"></i>
                            <h4>
                                Services We Offer
                            </h4>
                            <p>Deep expertise In next-gen offerings combining both Data & Application Skills.</p>
                        </div>

                        <div className='num'>
                            <div>
                                <h2 className='counter-number'>1235+</h2>
                                <p>Give Best Feedback
                                    Lorem Ipsum Dolor</p>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </>
    )
}

export default Home