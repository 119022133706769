import React from 'react'
import './services.css'
import icon from './image/3045674 (1).png';
import icon1 from './image/3822470.webp'
import icon2 from './image/Constructions.png'
const Services = () => {
    return (
        <>
            <div className='element' id='serve'>
                <div style={ { display: 'flex' } }>
                    <div className='widget'>
                    </div>
                    <div className='widget1'>
                    </div>
                </div>
                <div className='elementor-wri'>
                    <p>Leaders Who Trust Us</p>
                    <h3>Industries we serve</h3>
                </div>
                <div className='serCard'>
                    <div className='carD'>
                        <div >
                            <img src={ icon } alt="" width={ 40 } />
                            <h3>Retail Transformation & Consulting Services</h3>
                            <input type="checkbox" id="toggle" class="toggle" />
                            <p class="content"> Next-Gen Retail, Unified Commerce, & Superior Customer Experience!
                                Retailers are embracing it &
                                customers are loving it! Innovate faster,
                                reduce errors, lower costs, and cater to unique expectations
                                with personalized experiences.
                            </p>
                            <label for="toggle" class="read-more-toggle"><u>Read More</u> <i class="fa-solid fa-arrow-right"></i></label>
                            <label for="toggle" class="read-less-toggle"><u>Read Less</u></label>
                        </div>
                    </div>
                    <div className='carD1'>
                        <div className='box'>
                            <img src={ icon1 } alt="" width={ 40 } />
                            <h3>Manufacturing</h3>
                            <p style={ { color: '#7F7F7F', paddingTop: '10px' } }>Lorem ipsum dolor sit amet, <br />
                                consectetur adipiscing elit.
                            </p>
                            <button>
                                <a href="#">Learn more <i class="fa-solid fa-arrow-right"></i> </a>
                            </button>
                        </div>
                    </div>
                    <div className='carD2'>
                        <div >
                            <img src={ icon2 } alt="" width={ 40 } />
                            <h3>Constructions</h3>
                            <p style={ { color: '#7F7F7F', paddingTop: '10px' } }>Lorem ipsum dolor sit amet, <br />
                                consectetur adipiscing elit.
                            </p>
                            <button>
                                <a href="#">Learn more <i class="fa-solid fa-arrow-right"></i> </a>
                            </button>
                        </div>
                    </div>
                </div>
                {/* 
                 */}
                {/* 
                  */}
                <div className='testimonials'>
                    <h2>Our Testimonials</h2>
                    <div className='serCard2'>
                        <div className='scarD'>
                            <div>
                                <p class="content1">
                                    Indium helped Bloom and Early Autism Services build
                                    technology stack using agile PoD delivery model, that will eventually help
                                    1 million children with autism.
                                </p>
                                <p style={ { color: '#7F7F7F' } }>Chief Technology Officer
                                    | BLOOM
                                </p>
                                <h3>Nathan Uno</h3>
                            </div>
                        </div>
                        <div className='scarD1'>
                            <div className='sbox'>

                                <p class="content2">
                                    Max McNeil, SVP Operations, Community Reinvestment Fund in NY, shares his experience
                                    on the complexities he faced with three decades of data and how Indium
                                    enabled CRF to build a roadmap and implement a data lake solution, thereby helping
                                    empower people to deliver new financial solutions.
                                </p>


                                <p style={ { color: '#7F7F7F' } }>
                                    <p style={ { color: '#7F7F7F' } }>
                                        Senior Vice President Operations | CRF
                                    </p>
                                </p>
                                <h3>Mac McNeil</h3>
                            </div>
                        </div>
                        <div className='scarD2'>
                            <div >

                                <p class="content3">
                                    Indium Software helped Hitachi increase test coverage through
                                    automation from 20% to 75%
                                    in 4 months, thereby enabling them to focus on innovative new
                                    product development.
                                </p>
                                <p style={ { color: '#7F7F7F' } }>
                                    <p style={ { color: '#7F7F7F' } }>
                                        Solutions Architect & Senior Engineering Manager | Hitachi Vantara
                                    </p>
                                </p>
                                <h3>Senthil Karuppaiah</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Services