import React from 'react'
import './Custom.css'

const Custom = () => {
    return (
        <>
            <div>
                <div className='custom'>
                    <div className='bGrid'>
                        <div className='box2'>
                            <div className='baPic'></div>
                        </div>
                        <div className='box3'>
                            <p>EXPERT SKILL WE HAVE</p>
                            <h4>We Have The Skills And Expertise To Deliver High-Quality Custom Software</h4>
                            <div className='teamwork'>
                                <div className='tPic'></div>
                                <div className='teamWri'>
                                    <div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                                        <div className='lineGraph'>
                                            <h5>.NET Development </h5>
                                            <div className='lin line1'><span>85%</span></div>
                                            <h5>Android Development</h5>
                                            <div className='lin line1'><span>85%</span></div>
                                            <h5>IOS Development</h5>
                                            <div className='lin line1'><span>85%</span></div>
                                            <h5>Node Js Development</h5>
                                            <div className='lin line2'><span>75%</span></div>

                                            <div>
                                                <button>
                                                    <a href="#">GEt A QUOTE <i class="fa-solid fa-arrow-right"></i> </a>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Custom