import React from 'react'
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import img1 from './image/serv2.jpg'
import './Clients.css'

const Clients = () => {
    return (
        <>
            <div className='background-overlay'>
                <section className='parallax'>
                </section>
                <section className='slide-Client'>
                    <div className='elementor-container'>
                        <div className='ele-contaier'>
                            <div className='elementor-col'>
                                <p>TAKE THE TIME TO UNDERSTAND</p>
                                <div className='elemetor-hd'>
                                    <h3>We Work Closely With You To Develop A Customized Solution</h3>
                                </div>
                            </div>
                            <div className='widget-container'>

                            </div>
                            <div className='container-pic'>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Clients