import React from 'react'
import Navbar from './component/Navbar'
import Blog from './component/Blog'
import Clients from './component/Clients'
import Custom from './component/Custom'
import Home from './component/Home'
import Services from './component/Services'
import Team from './component/Team'
import Footer from './component/Footer'
import JoinUs from './component/JoinUs'

const App = () => {
  return (
    <>
      <Navbar />
      <Home />
      <Team />
      <Services />
      <Custom />
      <Clients />
      <Blog />
      <JoinUs />
      <Footer />
    </>
  )
}

export default App