import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <>
            <div className='footer'>
                <div className='elemeter-footer'>
                    <div className='logoF'>
                        <h2>Real Software Solution</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                    </div>
                    <div className='elemetor-col'>
                        <h4>Quick Links</h4>

                        <li>
                            <a href="#">
                                <span className="list-icon">
                                    <i aria-hidden="true" class="fas fa-caret-right"></i>
                                </span>
                                <span className="list-text">Homepage</span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span className="list-icon">
                                    <i aria-hidden="true" class="fas fa-caret-right"></i>
                                </span>
                                <span className="list-text">
                                    About Us
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span className="list-icon">
                                    <i aria-hidden="true" class="fas fa-caret-right"></i>
                                </span>
                                <span className="list-text">
                                    Our Services
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span className="list-icon">
                                    <i aria-hidden="true" class="fas fa-caret-right"></i>
                                </span>
                                <span className="list-text">
                                    Projects Work
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span className="list-icon">
                                    <i aria-hidden="true" class="fas fa-caret-right"></i>
                                </span>
                                <span className="list-text">
                                    Contact Us
                                </span>
                            </a>
                        </li>

                    </div>



                    <div className='elemetor-col-1'>
                        <h4>Customize Software</h4>

                        <li>
                            <a href="#">
                                <span className="list-icon">
                                    <i aria-hidden="true" class="fas fa-caret-right"></i>
                                </span>
                                <span className="list-text">
                                    Payment Gateway
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span className="list-icon">
                                    <i aria-hidden="true" class="fas fa-caret-right"></i>
                                </span>
                                <span className="list-text">
                                    Point Of Sales
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span className="list-icon">
                                    <i aria-hidden="true" class="fas fa-caret-right"></i>
                                </span>
                                <span className="list-text">
                                    Custom Warehouse
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span className="list-icon">
                                    <i aria-hidden="true" class="fas fa-caret-right"></i>
                                </span>
                                <span className="list-text">
                                    MLM Software
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span className="list-icon">
                                    <i aria-hidden="true" class="fas fa-caret-right"></i>
                                </span>
                                <span className="list-text">
                                    SAAS Software
                                </span>
                            </a>
                        </li>

                    </div>

                    <div className='elemetor-col-2'>
                        <h4>Head Office Address</h4>
                        <p style={ { marginBottom: '20px' } }>404, Elita Sqaure, VIP Circel, Uttran (Digital Valley), Surat</p>
                        <p>Contact NO : 9898098381</p>
                        <h4>Days Open</h4>
                        <p>Monday - Sturday 09 AM - 06 PM</p>
                        <h4>Privacy Policy</h4>
                        <a href="https://www.termsfeed.com/live/4305eaf9-5dd7-4b56-95cc-b60d2aa214fe">
                            https://www.termsfeed.com/live/4305eaf9-5dd7-4b56-95cc-b60d2aa214fe
                        </a>
                    </div>




                </div>
                <div className='elemetor-sec'>
                    <div className='elemeter-left'>
                        <p>REAL SOFTWARE SOLUTIOS</p>

                    </div>
                    <div className='elemeter-right'>
                        <div class="elementor-grid-item">
                            <a class="elementor-facebook " >
                                <i class="fab fa-facebook"></i>
                            </a>
                        </div>
                        <div class="elementor-grid-item">
                            <a class="elementor-facebook " >
                                <i class="fab fa-twitter"></i>
                            </a>
                        </div>
                        <div class="elementor-grid-item">
                            <a class="elementor-facebook " >
                                <i class="fab fa-youtube"></i>
                            </a>
                        </div>
                        <div class="elementor-grid-item">
                            <a class="elementor-facebook " >
                                <i class="fab fa-pinterest"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer