import React from 'react'
import './Blog.css'
import blog from './image/blog-pic.jpg';
import blog1 from './image/blog-pic1.jpg';
import blog2 from './image/blog-pic2.jpg';

const Blog = () => {
    return (
        <>
            <div className='blog' id='blog'>
                <div className='hd-ele'>
                    <p>BLOG CONTENT</p>
                    <h3>Lets Read Our Blog Post Content &
                        <br /> Writing Resource</h3>
                </div>
                <div className='elemetor-blog'>
                    <div className='elemetor-blogs'>
                        <div className='blogs'>
                            <div className='blog-pic'>
                                <img src={ blog } alt="" />
                            </div>
                            <div className='blog-ele'>
                                <h2>Tips Recomendation Remote Worker Today</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                                <button><a href="#">LEARN MORE</a></button>
                            </div>
                        </div>
                    </div>
                    <div className='elemetor-blogs'>
                        <div className='blogs'>
                            <div className='blog-pic'>
                                <img src={ blog1 } alt="" />
                            </div> <div className='blog-ele'>
                                <h2>Tips Recomendation Remote Worker Today</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                                <button><a href="#">LEARN MORE</a></button>
                            </div>
                        </div>
                    </div>
                    <div className='elemetor-blogs'>
                        <div className='blogs'>
                            <div className='blog-pic'>
                                <img src={ blog2 } alt="" />
                            </div> <div className='blog-ele'>
                                <h2>Tips Recomendation Remote Worker Today</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                                <button><a href="#">LEARN MORE</a></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blog