import React from 'react'
import './JoinUs.css'

const JoinUs = () => {
    return (
        <>
            <div className='ele-joinUs'>
                <div className='joiUs' id='joiUs'>
                    <div className='ele-join'>
                        <div className='join-hd'>
                            <p>Join Us</p>
                            <h1>Build Your dream career, <br />
                                Come Join Us
                            </h1>
                        </div>
                        <div style={ { width: '600px', textAlign: 'center', margin: 'auto', marginTop: '20px' } }>
                            <p>Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Est similique enim sequi
                                dolore sed velit blanditiis eveniet nostrum
                                nesciunt laborum aut quas eos nihil, recusandae
                                soluta qui in nulla. Tempora. Lorem ipsum dolor,
                                sit amet consectetur adipisicing elit. Blanditiis
                                quo quibusdam eum minima dicta iure quam fuga quae
                                reprehenderit consequuntur odit,
                                cum quas nesciunt consequatur voluptatibus
                                consectetur magni quasi quis.
                            </p>
                        </div>
                    </div>
                    <div className='skilName'>
                        <div className='ele-row'>
                            <div style={ { padding: '12px 67px' } }>Meritocratic</div>
                            <div style={ { padding: '12px 84px' } }>growth</div>
                            <div style={ { padding: '12px 13px' } }>Young & diverse leadership</div>
                            <div style={ { padding: '12px 57px' } }>Legacy of trust</div>
                            <div style={ { padding: '12px' } }>People-centric & hybrid work Culture</div>
                            <div style={ { padding: '12px' } }>Global opportunities in next-gen tech</div>
                            <div style={ { padding: '12px' } }>Continuous learning & upskilling</div>
                        </div>

                    </div>
                </div>
                <div className='ele-table'>
                    <div className='ele-details'>
                        <div className='vision' style={ { paddingTop: '78px' } }>
                            <h2>Vision </h2>
                            <div style={ { width: '500px', textAlign: 'center', margin: 'auto', marginTop: '20px' } }>
                                Our vision is to pioneer innovative
                                solutions that empower businesses to
                                hrive in the digital age. We aspire to
                                be a catalyst for positive change, leveraging
                                cutting-edge technologies to shape the future
                                of industries worldwide.
                            </div>
                        </div>
                        <div className=' Mission'>
                            <h2>Mission  </h2>
                            <div style={ { width: '500px', textAlign: 'center', margin: 'auto', marginTop: '20px' } }>
                                Our mission is to deliver exceptional software products and services that exceed
                                customer expectations. We are committed to fostering long-term partnerships built
                                on trust, integrity, and reliability. Through continuous innovation and a customer-centric
                                approach, we aim to drive tangible valuefor our clients and contribute to their success.
                            </div>
                        </div>
                        <div className='Goal' style={ { paddingTop: '78px' } }>
                            <h2>Goal  </h2>
                            <div style={ { width: '500px', textAlign: 'center', margin: 'auto', marginTop: '20px' } }>
                                Our goal is to become a leading provider of bespoke software solutions renowned for their
                                quality, efficiency, and effectiveness. We strive to consistently surpass
                                industry standards and set new benchmarks for excellence.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JoinUs